.active-mobile {
  a {
    color: #fb8b23 !important;
  }
  svg {
    stroke: #fb8b23;
    g {
      opacity: 1 !important;
      stroke: #fb8b23;
    }
    path {
      fill: #fb8b23 !important;
    }
  }
  span {
    color: #fb8b23 !important;
  }
}
.tiny-text {
  text-transform: none;
  font-weight: 600;
  font-stretch: ultra-expanded;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
  font-size: 0.6rem;
  color: #9f9f9f;

  @media only screen and (max-width: 330px) {
    display: none;
  }
}
.active-mobile-shipping {
  color: #fb8b23 !important;

  svg {
    stroke: #fb8b23;
    g {
      opacity: 1 !important;
      stroke: #fb8b23;
    }
    path {
      stroke: #fb8b23 !important;
    }
  }
}
.more-menu {
  @media only screen and (min-width: 330px) {
    justify-content: flex-end;
    height: 45.5px;
    padding-bottom: 3px;
  }
}
.button-mobile {
  padding: 4px;
}
