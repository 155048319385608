@media only screen and (max-width: 1023px) {
  .loggedin-container {
    display: block;
    .sidebar-large,
    .header-large {
      display: none;
    }
    .header-small {
      width: 95%;
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width: 1023px) {
  .loggedin-container {
    display: flex !important;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    width: 100%;
    .sidebar-large {
      width: 250px;
    }
    .main-content-wrapper {
      overflow-y: scroll;
      width: calc(100% - 250px);
      padding: 20px;
      .main-content {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
      }
    }
    .header-small,
    .mobile-nav {
      display: none;
    }
  }
}

.intercom-lightweight-app-launcher,
.intercom-namespace,
.intercom-1j4ykve {
  bottom: 89px !important;
  right: 83px !important;
}
