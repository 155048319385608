.form-settings {
  & .form-control {
    & input {
      background: hsl(0, 0%, 100%);
      display: block;
      width: 100%;
      height: 45px; // "calc(2.25rem + 2px)",
      border-radius: 16px; //".5rem",
      padding: 0.375rem 0.75rem;
      border: 0.25px solid #eee;
      font-size: 16px;
      color: rgb(74, 74, 74);
      transition: border - color 0.15s ease-in -out, box-shadow 0.15s ease -in -out;
      &:disabled {
        opacity: 0.6;
      }
      &:focus-visible {
        outline: 0;
      }
    }
    &.tel input {
      padding-left: 48px;
    }
  }
  & select {
    &.form-control {
      background: #ffffff;
      display: block;
      width: 100%;
      height: 45px; // "calc(2.25rem + 2px)",
      border-radius: 16px; //".5rem",
      border: 0.25px solid #eee;
      padding: 0.375rem 0.75rem;
      font-size: 16px;
      color: rgb(74, 74, 74);
      transition: border - color 0.15s ease-in -out, box-shadow 0.15s ease -in -out;
    }
  }
}
.eta-header {
  font-weight: 500;
  color: #222;
  text-align: center;
  text-overflow: clip;
}
.right {
  text-align: right;
}
