html,
body,
#root {
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

.App {
  height: 100%;
}

.loading-bar {
  z-index: 100000;
}

.loader-container {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.MuiAlert-root {
  color: #033b6c !important;
  background-color: white !important;
}
.styled-scrollbars {
  /* Foreground, Background */
  scrollbar-color: #999 #333;
}

.styled-scrollbars::-webkit-scrollbar {
  width: 5px;
  /* Mostly for vertical scrollbars */
  height: 5px;
  /* Mostly for horizontal scrollbars */
}

.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #465c70;
  border-radius: 5px;
}

.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: transparent;

}
.hide-column {
  display: none !important;
}
::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

/* Hide scrollbar for Firefox */
html {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Hide scrollbar for Edge and Internet Explorer 10+ */
body {
  -ms-overflow-style: none; /* Hide scrollbar */
}
