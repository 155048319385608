.auth-page {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  @media only screen and (min-width: 868px) {
    display: flex;
    flex-direction: row;
  }

  .left {
    width: 50%;
    padding: 2rem;
    display: block;
    overflow-y: scroll;
    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 868px) {
      width: 100%;
      height: 100%;
      margin-bottom: 5rem;
    }

    .form-wrapper {
      width: 100%;
      margin: 0 auto;
      max-width: 400px;
    }

    .bottom-control {
      .remember,
      .forgot {
        margin: auto 0;
      }

      .forgot {
        color: #fb8b23;
        &:active {
          opacity: 0.8;
        }
      }
    }

    .form-button {
      margin-top: 3rem;
      margin-bottom: 0.7rem;
      width: 100%;
      background-color: #033b6c;
      border-radius: 5px;
      padding: 13px;
      color: white;
      cursor: pointer;
      &.create {
        margin-top: 1rem;
      }
      &.disabled {
        background-color: #93a0ab;
      }
      &:active {
        opacity: 0.8;
      }
    }

    .google-button {
      display: flex;
      justify-content: center;
      width: 100%;
      border-radius: 5px;
      padding: 9px;
      border: 0.5px solid #393939;
      color: #3c3c3c;
      cursor: pointer;
      font-size: 1rem;
      margin: auto 0;
      &:active {
        opacity: 0.8;
      }
      .icon {
        font-size: 30px;
        margin-right: 15px;
      }
      span {
        margin: auto 0;
      }
    }
  }

  .right {
    width: 50%;
    height: 100%;
    overflow: hidden;
    background-color: #033b6c;
    padding: 4rem 2rem;
    position: relative;
    @media only screen and (max-width: 868px) {
      display: none;
    }
    &.create {
      .top-create {
        margin: 0 auto;
        max-width: 550px;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
        margin-bottom: 60px;
        .text-1 {
          color: white;
        }
        .text-2 {
          color: #ff9900;
        }
      }

      .middle-create {
        margin-bottom: 10px;
        img {
          margin: 0 auto;
          width: auto;
          height: 40vh;
        }
      }
    }
    .top {
      .img-wrapper {
        img {
          width: 70%;
          margin: 0 auto;
        }
      }
      .desc {
        color: white;
        width: 70%;
        margin: 1rem auto;
        font-weight: 300;
      }
    }

    .middle {
      display: flex;
      flex-direction: row;
      margin: 0 auto;

      .ship {
        transform: translateY(80px);
      }
      img {
        height: 50vh;
      }
    }

    .bottom {
      position: absolute;
      bottom: 7%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.hero-text{
  margin: 0 auto!important;
  max-width: 550px!important;
  text-align: center!important;
  font-size: 35px!important;
  font-weight: 600!important;
  line-height: 50px!important;
  margin-bottom: 30px!important;
  margin-top: 30px!important;
}

.invinsible-scroll{
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.google-button {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  padding: 9px;
  border: 0.5px solid #393939;
  color: #3c3c3c;
  cursor: pointer;
  font-size: 1rem;
  margin: auto 0;
  &:active {
    opacity: 0.8;
  }
  .icon {
    font-size: 30px;
    margin-right: 15px;
  }
  span {
    margin: auto 0;
  }
}

.form-button {
  margin-top: 3rem;
  margin-bottom: 0.7rem;
  width: 100%;
  background-color: #033b6c;
  border-radius: 5px;
  padding: 13px;
  color: white;
  cursor: pointer;
  &.create {
    margin-top: 1rem;
  }
  &.disabled {
    background-color: #93a0ab;
  }
  &:active {
    opacity: 0.8;
  }
}
.link:hover{
  text-decoration: underline;
}