.side_dashes {
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
  margin: 5px 0 29px 0;
}

.side_dashes:before {
  content: "";
  display: block;
  width: 147.5px;
  height: 1.33px;
  position: absolute;
  background: rgba(196, 196, 196, 0.5);
  left: 0;
  top: 50%;
  z-index: -2;
}

.side_dashes:after {
  content: "";
  display: block;
  width: 147.5px;
  height: 1.33px;
  position: absolute;
  background: rgba(196, 196, 196, 0.5);
  right: 0;
  top: 50%;
  z-index: -2;
}

.side_dashes_span {
  background-color: #fff;
  height: 25px;
  padding: 0 13.4px 0 14.5px;
  font-size: 22.62px;
  font-style: normal;
  font-weight: 500;
  line-height: 24.88px;
  letter-spacing: 0em;
  text-align: left;
}

/* Media Query */
@media only screen and (max-width: 1536px) {
  .side_dashes {
    font-size: 0.8rem;
    width: 100%;
    margin: 4px 0 23.2px 0;
  }
  .side_dashes_span {
    height: 20px;
    padding: 0 10.72px 0 11.6px;
    font-size: 22.62px;
    line-height: 24.88px;
  }
}
@media only screen and (max-width: 1280px) {
  .side_dashes {
    font-size: 0.67rem;
    width: 100%;
    margin: 3.3px 0 19.33px 0;
  }
  .side_dashes_span {
    height: 16.67px;
    padding: 0 8.93px 0 9.67px;
    font-size: 15.08px;
    line-height: 16.59px;
  }
}
@media only screen and (max-width: 1024px) {
  .side_dashes_span {
    height: 45px;
    font-size: 1.44rem;
    line-height: 1.7rem;
    padding: 0 18.05px 0 25.2px;
  }

  .side_dashes {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .side_dashes_span {
    height: 18px;
    font-size: 1.35rem;
    line-height: 2rem;
    padding: 0 18.05px 0 25.2px;
  }

  .side_dashes {
    width: 100%;
  }
}
@media only screen and (max-width: 414px) {
  .side_dashes_span {
    width: 125px;
    height: 18px;
    font-size: 1rem;
    line-height: 1.1rem;
    padding: 0 7.22px 0 10.08px;
  }

  .side_dashes {
    width: 100%;
  }
}
