@media only screen and (min-width: 1024px) {
  .tracking-page-container {
    width: 100%;
    margin-top: 20px;

    .search-container {
      margin: 0 auto 20px auto;
    }

    .tracking-main {
      display: flex;
      flex-direction: row;
      .info-map {
        width: 70%;
        padding: 0 20px 20px 0;
        border-right: 1px solid rgb(224, 224, 224);

        .info-row-one {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .info1 {
            width: 50%;
            min-width: 190px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .info1-title {
              font-weight: 600;
              font-size: 1rem;
              color: #033b6c;
            }
            .info1-desc {
              color: #858585;
              font-size: 12px;
            }
          }
        }

        .start-tracking {
          display: none;
        }

        .info-row-two {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .info2 {
            width: calc(25% - 15px);
            margin-top: 15px;
            margin-right: 15px;
            min-width: 190px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 15px;

            .info2-title {
              font-size: 0.9rem;
              color: #033b6c;
              font-weight: 600;
            }
            .info2-desc {
              color: #858585;
              font-size: 10px;
            }
          }
        }

        .list-events {
          display: none;
        }

        .map-container {
          width: 100%;
          margin-top: 20px;
          .map-wrapper {
            width: 100%;
            height: 400px;
          }
        }
      }

      .events {
        padding-left: 20px;
        width: 30%;
        .timeline-wrapper {
          margin-top: 10px;
          height: calc(100vh - 120px);
          overflow-y: scroll;
        }
      }
    }
  }
}
.large-tracking {
  display: block !important;
}
.small-tracking {
  display: none !important;
}
.download-report {
  margin-top: 20px;
  .pry-btn {
    margin-left: 0;
  }
}

.events {
  .events-title {
    font-size: 18px;
    font-weight: 600;
    color: #033b6c;
  }

  .timeline {
    margin-bottom: 40px;
    background-color: white;
    &.add-padding {
      padding: 15px;
    }
    .timeline-group {
      margin-bottom: 20px;
    }
  }
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  max-width: 150px;
  &.container {
    max-width: unset;
    margin: 20px 0 10px 0;
    font-size: 15px;
    color: #b85c06;
    font-weight: 500;
    padding-right: 15px;
    &:nth-of-type(1) {
      margin-top: unset;
    }
  }
  &:hover {
    color: #033b6c;
  }
  &:active {
    opacity: 0.8;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #033b6c;
  }
  .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.unlock-all {
  margin-top: 20px;
  .text121 {
    color: #033b6c;
    font-weight: 600;
  }
  .pry-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
  }
  .pry-blink {
    background-color: green;
    animation-name: blink;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes blink {
    from {
      background-color: #033b6c;
    }
    to {
      background-color: #ff810c;
    }
  }
}

.no-data {
  margin-top: 80px;
  min-height: 200px;
  text-align: center;

  @media only screen and (min-width: 460px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 460px) {
    margin-top: 20px;
  }

  .no-data-content {
    margin: 0 auto;
    text-align: start;
    max-width: fit-content;
    .text {
      margin-bottom: 5px;
      &:first-child {
        font-weight: 500;
      }
    }
  }
}

.no-info {
  font-size: 12px;
  padding: 10px 0;
  color: #858585;
}

@media only screen and (max-width: 1024px) {
  .tracking-page-container {
    width: 100%;
    margin-top: unset;
    padding-bottom: 90px;
    .search-container {
      padding: 0 10px 20px 10px;
    }

    .tracking-main {
      display: block;
      .search-container {
        padding: 0 10px 30px 0;
      }
      .info-map {
        width: 95%;
        margin: 0 auto;

        .info-row-one {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          justify-content: space-between;
          max-width: 400px;
          .info1 {
            width: 50%;
            display: flex;
            flex-direction: column-reverse;
            &.large {
              display: none;
            }
            .info1-title {
              font-weight: 600;
              font-size: 19px;
              color: #033b6c;
            }
            .info1-desc {
              color: #858585;
              font-size: 12px;
            }
          }
        }

        .start-tracking {
          .pry-btn {
            transform: translateY(5px);
          }
        }

        .info-row-two {
          margin-top: 17px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .info2 {
            margin-right: 15px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;
            margin-bottom: 15px;
            .info2-title {
              font-size: 15px;
              color: #033b6c;
              font-weight: 600;
            }
            .info2-desc {
              color: #858585;
              font-size: 10px;
            }
          }
        }

        .list-events {
          margin: 10px 0;
          .pry-btn {
            width: calc(100% - 5px);
            margin: 0 auto;
          }
        }

        .map-container {
          width: 100%;
          margin-top: 20px;

          .map-wrapper {
            width: 100%;
            height: 350px;
            object-fit: cover;
          }
        }
      }

      .events {
        width: 95%;
        margin: 30px auto;
      }
    }
  }

  .small-tracking {
    display: block !important;
  }
  .large-tracking {
    display: none !important;
  }
  .info-row-one {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    margin: 0.05rem !important;
    max-width: 100% !important;
    .cram {
      display: block !important;
    }
    .info1 {
      width: 100% !important;
      max-width: 100% !important;
      display: grid !important;
      align-items: center;
      color: #033b6c !important;
      grid-template-columns: 1fr 1fr;
      .info1-title {
        font-size: 1rem !important;
        margin-left: 5px;
      }
      .info1-desc {
        font-size: 0.9rem !important;
        padding: auto 0;
        margin-left: 5px;
      }
    }
  }

  .info-row-two {
    margin: 0.05rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    .cram {
      display: none !important;
    }
    .info2 {
      width: calc(100% - 5px);
      .info2-title {
        font-size: 1rem !important;
        margin-left: 15px;
        margin-bottom: 5px;
      }
      .info2-desc {
        font-size: 0.95rem !important;
        margin-left: 13px;
      }
    }
  }

  .timeline {
    margin-bottom: 40px;
    background-color: white;
    &.add-padding {
      padding: 15px;
    }
    .timeline-group {
      margin-bottom: 20px;
    }
  }

  .events-title {
    font-size: 0.9rem;
    font-weight: 600;
    color: #033b6c !important;
  }
}

@media only screen and (max-width: 720px) {
  .info-row-one {
    grid-template-columns: 1fr;
    .cram {
      display: none !important;
    }
    .info1 {
      .info1-title {
        font-size: 0.9rem !important;
      }
      .info1-desc {
        font-size: 0.8rem !important;
      }
    }
  }

  .info-row-two {
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    .cram {
      display: block !important;
    }
    .info2 {
      .info2-title {
        font-size: 0.9rem !important;
      }
      .info2-desc {
        font-size: 0.85rem !important;
        margin-left: 13px;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .info-row-one {
    .info1 {
      .info1-title {
        font-size: 0.8rem !important;
      }
      .info1-desc {
        font-size: 0.75rem !important;
      }
    }
  }

  .info-row-two {
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    .info2 {
      .info2-title {
        font-size: 0.85rem !important;
      }
      .info2-desc {
        font-size: 0.8rem !important;
        margin-left: 13px;
      }
    }
  }
}
