.base{.drop_area {
  width: 100%;
}
.drop_zone {
  min-height: 10rem;
  border: 2px dashed #033b6c !important;
  border-radius: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#033b6c;
}
}
