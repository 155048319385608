@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: none;
}

/* Hide scrollbar for Edge and Internet Explorer 10+ */
body {
  -ms-overflow-style: none; /* Hide scrollbar */
}

button,
input {
  outline-color: transparent;
}

.map-cover {
  background: url("../assets/images/map.svg") no-repeat;
  background-position: center center;
  background-size: cover;
}

.partner-card {
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
}

.testimonial-card-active {
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
}

.testimonial-card-disabled {
  filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.02)) drop-shadow(0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852))
    drop-shadow(0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481)) drop-shadow(0px 20px 13px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185))
    drop-shadow(0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481));
}

.form-control-dashboard {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
}

.active-shipment {
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02);
  background: #f4f3f3;
}

.eta-per {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
}

.MuiTimelineItem-missingOppositeContent:before {
  display: none;
}

.MuiTimeline-root {
  padding: 0 !important;
}

.onboard-map,
.onboard-map-mobile {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
}

.marker-out {
  background: linear-gradient(0deg, rgba(3, 59, 108, 0.2), rgba(3, 59, 108, 0.2)), #ffb8b8;
  transform: rotate(-180deg);
}

.marker-inner {
  background: linear-gradient(0deg, #033b6c, #033b6c), #ffb8b8;
  transform: rotate(-180deg);
}

.mobile-bottom-nav {
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
}

/* scrollbar styling */
.hide-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}

.hide-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f8fafc;
}

.gm-style .gm-style-iw-c {
  position: relative !important;
  box-shadow: none !important;
  max-width: 11rem !important;
}

.gm-style-iw-d + button {
  display: none !important;
}

/* Tracey */
/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

/* Ensure content is scrollable */
.scrollable {
  overflow: auto; /* Make sure overflow is set to auto to enable scrolling */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* React Markdown */
.markdown-style li p {
  display: inline;
}

.markdown-style ul,
.markdown-style ol {
  margin: 0;
  padding: 0.5rem 1rem;
  list-style-position: inside;
  list-style: auto !important;
}

.markdown-style a {
  color: #033b6c;
  text-decoration: underline;
}
