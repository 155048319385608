.form-header {
  margin-bottom: 2rem;

  .title {
    color: #282c40;
    font-size: 2.3rem;
    font-weight: 500;
    margin-top: 2rem;
    @media only screen and (max-width: 868px) {
      font-size: 1.8rem;
    }
  }
  .desc {
    span {
      color: #282c40;
      font-weight: 300;
      font-size: 1.3rem;
      @media only screen and (max-width: 868px) {
        font-size: 1rem;
      }
    }
  }
}

.form-input {
  position: relative;
  .form-label {
    color: #282c40;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  .form-field {
    margin-bottom: 1rem;
    background: transparent;
  }
  .cancel-icon,
  .check-icon {
    position: absolute;
    font-size: 3rem;
    top: 17px;
    right: 0;
  }
  .check-icon {
    color: #2dd348;
  }
  .cancel-icon {
    color: #f0424c;
  }
}

.FormButton_side_dashes__1I3BJ {
  margin-bottom: 13px;
}
