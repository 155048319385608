.title112 {
  color: #393939;
  font-weight: 600;
  margin-bottom: 4px;
}

.timeline-item {
  margin-bottom: 10px;
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }

  .left12 {
    display: flex;
    .icon12 {
      margin-right: 3px;
      transform: translateY(3px);
    }
    .title12 {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .desc12 {
      font-size: 10px;
      margin-top: 3px;
      color: #858585;
    }
  }

  .time12 {
    color: #393939;
    font-size: 11px;
    font-weight: 500;
    margin-left: 20px;
  }
}

.MuiTimelineConnector-root {
  background-color: #4bd37b !important;
}
