@media only screen and (min-width: 1024px) {
  .dashboard-large {
    margin-top: 15px;
  }

  .search {
    width: 100%;
    max-width: 900px;
    margin-bottom: 20px;
  }
}

.items-wrapper {
  width: 100%;
  display: flex;
  .left {
    width: 75%;
  }
}

.css-u1h1jo-Control {
  padding: 0 6px;
}

@media only screen and (max-width: 1023px) {
  .dashboard-mobile {
    width: 95%;
    margin: 0 auto;
  }
  .dashboard-large {
    display: none;
  }
}

.username {
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
}

.MuiMenu-paper {
  transform: translate(-5px, 7px) !important;
  color: #4a4a4a !important;
}

.MuiMenu-list {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

// .MuiPopover-paper {
//   box-shadow: unset !important;
// }

.interact {
  cursor: pointer;
  &:active {
    opacity: 0.9;
  }
}

.eta-option {
  &:active {
    opacity: 0.7;
  }
}
.link:hover{
  text-decoration: underline;
}