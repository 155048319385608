.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;

  .upload-button {
    font-weight: 600;
    color: #033b6c;
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid #033b6c;
    margin: 0 auto;
  }
}

.select-shipments-container {
  .close {
    transform: translateY(-15px);

    &:hover {
      color: #033b6c;
    }

    &:active {
      opacity: 0.8;
    }
  }

  .page-title {
    font-weight: 600;
    font-size: 22px;
    color: #033b6c;
  }

  .empty {
    margin-top: 150px;
    text-align: center;
  }

  .list-items {
    margin-top: 20px;
    overflow: scroll;
    height: 465px;
    padding-right: 15px;

    .item {
      margin-bottom: 25px;

      .block {
        width: 25%;
        font-size: 14px;
      }

      .title {
        color: #393939;
        font-size: 12px;
        margin-bottom: 5px;
        padding: 2px;
      }

      .container {
        color: #393939;
        font-weight: 600;
        padding: 2px;
      }

      .bill {
        color: #033b6c;
        font-weight: 600;
        padding: 2px;
      }

      .line {
        color: #393939;
        padding: 2px;
      }
    }

    .track-btn {
      transform: translateY(5px);
    }
  }
}

.notification-container {
  width: 100vw;
  max-width: 400px;
  padding: 15px;
  display: block;

  .no-notification {
    padding: 20px 0;
    text-align: center;
  }

  .loading {
    display: center;
    justify-content: center;
  }

  .notification-item-container {
    display: flex;
    flex-direction: row;
    padding: 10px 5px 10px 7px;
    border-bottom: 1px solid rgb(221, 221, 221);
    cursor: pointer;
    &:hover {
      background-color: #d5d5d5;
    }

    &:last-of-type {
      border-bottom: unset;
    }

    .left111 {
      padding-right: 10px;

      .img111 {
        transform: translateY(3px);
      }
    }

    .right111 {
      display: block;

      .title111 {
        color: #033b6c;
        font-weight: 600;
        font-size: 14px;
      }

      .content111 {
        margin: 5px 0;
        font-size: 12px;
        color: #979797;
      }

      .time111 {
        color: #979797;
        font-size: 10px;
      }
    }
  }

  // .notification-item-container:hover {
  //   background-color: #f5f5f5;
  // }
}

.MuiPopover-paper {
  transform: translateY(10px) !important;
}

.tracking-started-container {
  position: relative;
  padding: 10px;
  z-index: 100000;
  text-align: center;
  .background {
    position: absolute;
    z-index: -1 !important;

    .bg-image {
      transform: translate(-140px, -40px);
      z-index: -1 !important;
      opacity: 0.7;
    }
  }

  .row-1212 {
    display: flex;
    justify-content: center;
    position: relative;

    .tick {
      position: absolute;
      transform: translateY(15px);
    }
  }

  .row-2212 {
    font-weight: 700;
    font-size: 25px;
    color: #033b6c;
    margin: 10px 0;
  }

  .row-3212 {
    color: #393939;
    font-size: 19px;
    font-weight: 600;
  }

  .row-4212 {
    text-align: center;
    color: #858585;
    font-size: 12px;
    width: 90%;
    max-width: 313px;
    margin: 10px auto;
  }
}

.MuiBox-root {
  overflow: hidden !important;
}
.upload-title {
  font-size: 1rem;
  color: #033b6c;
  font-weight: 600;
  font-size: 0.95rem;
}
.upload-text {
  font-size: 0.8rem;
  color: #393939;
}
.excel-bd {
  display: grid;
  grid-gap: 0.5rem 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}


// UpdateUserModal

.__floater .__floater__open {
  height: 100% !important;
  overflow-y: scroll !important;
}