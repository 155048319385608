.table-small-container {
  width: 95%;
  margin: 0 auto;
  @media only screen and (min-width: 690px) {
    display: none;
  }
  .page-title {
    color: #033b6c;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .filter-bar {
    margin-bottom: 20px;
    .row-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .from,
      .to {
        width: 48%;
        display: flex;
        legend {
          span {
            color: #033b6c !important;
          }
        }
      }
    }

    .row-two {
      margin-top: 15px;
      .MuiOutlinedInput-root {
        width: 100% !important;
      }
    }
    .MuiOutlinedInput-root {
      border-radius: 8px !important;
    }

    .MuiOutlinedInput-input {
      border-color: rgba(0, 0, 0, 0.08) !important;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      border: unset !important;
    }
  }

  .container {
    font-size: 15px;
    font-weight: 400 !important;
  }

  .table-header {
    font-weight: 600 !important;
    font-size: 15px;
  }
  .row-small {
    &:active {
      opacity: 0.6;
    }
  }
}

.table-large-container {
  @media only screen and (min-width: 690px) and (max-width: 1023px) {
    width: 97%;
    margin: auto;
  }

  & {
    margin-top: 20px;
  }

  @media only screen and (max-width: 690px) {
    display: none;
    background-color: yellow;
  }
  .filter-bar {
    margin-bottom: 20px;

    .row-two {
      margin: 0 auto;
      width: 100%;
      max-width: 1300px;
      display: flex;
      justify-content: space-around;
      box-shadow: 0px 3px 25px -5px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 0 10px;

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
        border-right-color: unset !important;
        border-color: unset !important;
      }

      .css-186xcr5 {
        span {
          display: none !important;
        }
      }
      .from,
      .to {
        width: 20%;
        display: flex;
      }

      .calender-prefix {
        margin: auto 0;
        margin-right: 10px;
        font-weight: 600 !important;
      }
    }
  }

  .table-header {
    font-weight: 600 !important;
    font-size: 15px;
  }
  .container {
    font-size: 15px;
    font-weight: 400 !important;
  }

  .map-btn-container {
    width: 100%;
    .map-btn {
      margin-left: auto;
      margin-right: 0;
      width: 80px;
      text-align: center;
      background-color: #033b6c;
      color: white;
      padding: 7px 5px;
      cursor: pointer;
      border-radius: 4px;
      &:active {
        opacity: 0.9;
      }
    }
  }
}

.table-container {
  width: 97%;
  margin: auto;
  padding: 8px;
  .filter-bar {
    margin-bottom: 20px;

    .row-two {
      margin: 0 auto;
      width: 100%;
      max-width: 1300px;
      display: flex;
      justify-content: space-around;
      box-shadow: 0px 3px 25px -5px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 0 10px;

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
        border-right-color: unset !important;
        border-color: unset !important;
      }

      .css-186xcr5 {
        span {
          display: none !important;
        }
      }

      .from,
      .to {
        width: 20%;
        display: flex;
      }

      .calender-prefix {
        margin: auto 0;
        margin-right: 10px;
        font-weight: 600 !important;
      }
    }
  }

  .table-header {
    font-weight: 600 !important;
    font-size: 15px;
  }

  .container {
    font-size: 15px;
    font-weight: 400 !important;
  }

  .map-btn-container {
    width: 100%;

    .map-btn {
      margin-left: auto;
      margin-right: 0;
      width: 80px;
      text-align: center;
      background-color: #033b6c;
      color: white;
      padding: 7px 5px;
      cursor: pointer;
      border-radius: 4px;

      &:active {
        opacity: 0.9;
      }
    }
  }
}

.MuiTableContainer-root {
  box-shadow: unset !important;
}

.container-image {
  height: 20px;
}

.stage {
  font-weight: 400 !important;
}

.status {
  font-weight: 600 !important;
  &.ontime {
    color: #4bd37b !important;
  }
  &.late {
    color: #fb8b23 !important;
  }
  &.critical {
    color: #ff1000 !important;
  }
}

.view-more {
  justify-content: center;
  padding-bottom: 100px !important;
  z-index: 31;
  @media only screen and (min-width: 1024px) {
    padding-bottom: 0px !important;
  }
}

.pry-btn {
  margin: 0 1rem;
  width: fit-content;
  text-align: center;
  background-color: #033b6c;
  color: white;
  padding: 7px 18px;
  cursor: pointer;
  border-radius: 4px;

  &:active {
    opacity: 0.9;
  }
  .warn-btn {
    background-color: #fb8b23 !important;
  }
}

.grey-text {
  color: #393939 !important;
}

.MuiTableCell-root {
  padding: 15px !important;
}

.MuiTableRow-root:nth-of-type(odd) {
  background-color: #f9fcff !important;
}

.MuiMenu-list {
  max-height: 500px !important;
}

.refresh-button:active {
  opacity: 0.7;
}

.sorting {
  color: rgba(3, 59, 108, 1);
  text-decoration-color: rgba(3, 59, 108, 0.075);
  text-decoration: underline;
  text-decoration-style: dotted;
}
.hide-column {
  display: none !important;
}
.styled-scrollbars {
  /* Foreground, Background */
  scrollbar-color: #999 #333;
}

.styled-scrollbars::-webkit-scrollbar {
  width: 5px;
  /* Mostly for vertical scrollbars */
  height: 5px;
  /* Mostly for horizontal scrollbars */
}

.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #465c70;
  border-radius: 5px;
}

.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: transparent;
}
.hide-column {
  display: none !important;
}
.styled-scrollbars {
  /* Foreground, Background */
  scrollbar-color: #999 #333;
}

.styled-scrollbars::-webkit-scrollbar {
  width: 5px;
  /* Mostly for vertical scrollbars */
  height: 5px;
  /* Mostly for horizontal scrollbars */
}

.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  background: #465c70;
  border-radius: 5px;
}

.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: transparent;
}
