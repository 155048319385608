.nav-item {
  transform: translateY(3px);
  font-weight: 300;
  opacity: 0.6;
  color: white;
}

.active {
  background-color: #fb8b23;
  border-radius: 7px;
  .nav-item {
    font-weight: 500;
    opacity: 1;
    transform: translateX(1px);
  }
  svg {
    path {
      fill: white !important;
    }
    g {
      opacity: 1 !important;
    }
  }
}

.shipping-header{
  .nav-item {
    transform: translateX(1px);
  }
}

.menu {
  display:flex;
  flex-direction: column;
  row-gap: 2px;
}
.active-shipping-header{
  // background-color: #fb8b23;
  border-bottom: #fb8b23 1px dashed;

  .nav-item {
    font-weight: 500;
    opacity: 1;
    transition-duration: 1000ms;
    transform: translateX(1px);
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
  }
  svg {
    stroke: white;
    g {
      opacity: 1 !important;
      stroke: white;
    }
    path {
      stroke: white !important;
    }
  }
}
.active-shipping {
  background-color: #fb8b23;
  border-radius: 7px;
  color:white;
  .menu-item {
      background-color: #fb8b23;
  }

  .nav-item {
    width:100%;
    background-color:#fb8b23;
    font-weight: 500;
    opacity: 1;

  }
  svg {
    stroke: white;
    g {
      opacity: 1 !important;
      stroke: white;
    }
    path {
      stroke: white !important;
    }
  }

}

@media only screen and (max-width: 1024px) {
  .search-box {
    padding: 12px 8px;
  }
}

.marker-content {
  padding: 10px;
  .container232 {
    font-size: 13px;
    color: grey;
    font-weight: 500;
    margin-bottom: 5px;
    span {
      font-weight: 400 !important;
    }
  }
}

.notification-icon {
  display: relative;
  .unread-count {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 4px;
    border-radius: 10px;
    background-color: #fb8b23;
    line-height: 20px;
    color: white;
    transform: translate(5px, -10px) !important;
  }
}
// .h-proper{
//   min-height: 2.5rem !important;
//   height: 2.5rem !important;
//   overflow-y: clip;
// }

